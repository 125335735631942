
  import { Vue, Component, Watch } from "vue-property-decorator";
  import { CentroCustoService, ContaCorrenteService, ContaGerencialService, DespesaService,FormaPagamentoService,SituacaoDespesaParcelaService} from "@/core/services/financeiro";
  import { DespesaBaixa,Despesa,DespesaParcela} from "@/core/models/financeiro";
  import { EmpresaService } from "@/core/services/compras/EmpresaService";
  import { EmpreendimentoService, PessoaService } from "@/core/services/cadastros";
  import { mixins } from "vue-class-component";
  import ListPage from "@/components/shared/ListPage";
  import { EnumOrigemDespesa } from "@/core/models/financeiro/OrigemDespesa"; 
  import { SituacaoColor, TableToExcel } from '@/assets/scripts/helper';

@Component
export default class ListaDespesa extends mixins(Vue, ListPage) {
  service = new DespesaService();
  item = new Despesa();

  itemParcela = new DespesaParcela();
  baixa = new DespesaBaixa();   
  titulo: string = "Despesa";
  subTitulo: string = "Lista das despesas cadastradas no Sistema"; 
 
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  dialogVisualizar: boolean = false;
  dialogEditar: boolean = false;
  dialogBaixar: boolean = false;

  sheet: boolean = false;
  lista: any = [];
  totalLista:any = 0;

  empresas = [];
  pessoas = [];
  situacoes = [];
  empreendimentos:any = [];
  listaCentroCusto = [];
  listaContaGerencial = [];
  contaCorrentes = [];
  formasPagamento = [];

  options: any = {
    itemsPerPage: 50,
  };

  headers: any[] = [
    { text: "", value: "actions", align: "start",  sortable: false},
    { text: "Id", value: "despesa.id" },
    { text: "Empresa", value: "empresaNome"},
    { text: "Num. Doc", value: "numeroDocumento" },
    { text: "Parcela", value: "parcelaStr", sortable: false},
    { text: "Favorecido", value: "despesa.fornecedorNome" },
    { text: "Valor", value: "valorParcela" },
    { text: "Valor Pago", value: "valorPagoParcela", sortable: false},
    { text: "Saldo", value: "saldoParcela", sortable: false},
    { text: "Situação", value: "situacaoNome" },
    { text: "Vencimento", value: "dataVencimentoParcela" },
  ]; 

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Despesa', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  buttons = [
    { id: 1, label: "5", color: "red lighten-2", active: true, dias: -5 },
    { id: 2, label: "15", color: "red lighten-2", active: true, dias: -15 },
    { id: 3, label: "30", color: "red lighten-2", active: true, dias: -30 },
    { id: 4, label: "HOJE", color: "blue lighten-2", active: true, dias: 0 },
    { id: 5, label: "30", color: "green lighten-2", active: true, dias: 30 },
    { id: 6, label: "15", color: "green lighten-2", active: true, dias: 15 },
    { id: 7, label: "5", color: "green lighten-2", active: true, dias: 5 },
  ]

  toggleButton(button) {
    if (button.active){
      this.filterByDias(button.dias); this.resetButtons();
      button.active = false;
    } else {
      button.active = true; this.filter.dataVencimentoInicial = ""; this.filter.dataVencimentoFinal ="";
      this.Atualizar();
    }
  }

  filterByDias(dias) {
    const date = new Date(new Date().setDate(new Date().getDate() + dias));
    this.filter.dataVencimentoInicial = new Date().yyyymmdd();
    this.filter.dataVencimentoFinal = new Date().yyyymmdd();

    if(dias >=0){
      this.filter.dataVencimentoFinal = new Date(date).yyyymmdd();
    }else if(dias < 0){
      this.filter.dataVencimentoInicial = new Date(date).yyyymmdd();
    } 
    this.Consultar();
  }

  resetButtons() {
    this.buttons.forEach((button) => (button.active = true));
  }
         
  @Watch("options", { deep: true })
  Atualizar() {      
    const { page, itemsPerPage, sortBy, sortDesc, search, columns  } = this.options;   
    this.loading = true;   

    this.service.ListarParcelasOtimizadas(page,itemsPerPage,sortBy,sortDesc,search, columns, this.filter, '').then(
      (res) => {
        this.lista = res.data.items;
        this.totalLista = res.data.count; 
      },  
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    ).finally(() => (this.loading = false));
  } 

  Consultar() {
    this.options.page = 1 
    this.Atualizar();
    this.sheet = false;  
  }
   
  Novo() {
    this.item = new Despesa();
    this.item.origemId = EnumOrigemDespesa.Financeiro;
    this.dialogCadastro = true;
  }
  
  DetalharParcela(item) {
    this.service.ObterParcelaPorId(item.id, "Situacao, Baixas, Baixas.FormaPagamento, Baixas.Comprovante, Despesa.Classificacoes, Despesa.Tipo, Despesa.Classificacoes.CentroCusto, Despesa.Classificacoes.ContaGerencial,Despesa.Documentos, Despesa.TipoDocumento, Despesa.Fornecedor, Despesa.Empreendimento").then((res) => {       
      this.itemParcela = res.data;
      this.dialogVisualizar = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  }

  EditarDespesa(item){
    this.service.ObterPorId(item.despesa.id, "Parcelas.Baixas, Classificacoes, Documentos").then(
      (res) => {       
        this.item = res.data;
        this.item.dataEmissao = new Date(res.data.dataEmissao).yyyymmdd();
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  } 
 
  ClonarDespesa(item){
    this.service.ObterPorId(item.despesa.id, "Parcelas, Parcelas.Baixas, Classificacoes, Documentos").then((res) => {   
      this.item = this.setDespesa(res.data);
      this.dialogCadastro = true;
    },
      
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  }

  setDespesa(despesa){
    let despesaNova = despesa  

    despesaNova.id = 0;
    despesaNova.documentos = [];
    despesaNova.dataEmissao = new Date(despesa.dataEmissao).yyyymmdd();  

    despesaNova.parcelas = despesaNova.parcelas.map(x => ({
      ...x,
      id: 0,
      baixas: [],
      situacaoId: 1,
      linhaDigitavelBoleto: ''
    }));

    despesaNova.classificacoes = despesaNova.classificacoes.map(x => ({
      ...x,
      id: 0
    }));

    return despesaNova;
  }

  EditarParcela(item) {
    this.service.ObterParcelaPorId(item.id).then(
    (res) =>{
      this.itemParcela = res.data;
      this.itemParcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
      this.dialogEditar = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }) 
  }

  BaixarParcela(item) {
    this.service.ObterParcelaPorId(item.id, 'Despesa, Baixas').then(
    (res) =>{
      this.itemParcela = res.data;
      this.itemParcela.dataVencimento = new Date(res.data.dataVencimento).yyyymmdd();
      this.dialogBaixar = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    })
  }

  CancelarParcela(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.CancelarParcela(item.id).then(
          (res) => {
            if (res.status == 200) { 
              this.Atualizar();
              return res;
            } 
          },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso2",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
            return err;
          }); 
          
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
      
          this.$swal("Aviso", result.value.status == 200 ? result.value.data : result.value.response.data, result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }

  SituacaoColor(item){
    return SituacaoColor(item)
  }
 
  ExportarExcel(tabela: any, worksheet?: string) {
    TableToExcel(tabela, worksheet);
  }

  ResetFiltro(){
    this.filter = {situacaoId:[1,4],contaCorrenteId:0, empresaId:0, fornecedorId:0, numeroDocumento:"", despesaId:0, pedidoId:0,dataVencimentoInicial:"", dataVencimentoFinal:"", dataPagamentoInicial:"",dataPagamentoFinal:"",empreendimentoId:0, centroCustoId:0,contaGerencialId:0, formaPagamentoId:0}
  } 
 
 filter: {
    situacaoId:any[],
    contaCorrenteId:number;
    empresaId: number;
    fornecedorId: number;
    numeroDocumento: string;
    despesaId:number;
    pedidoId:number;
    dataVencimentoInicial: string;
    dataVencimentoFinal: string;
    dataPagamentoInicial: string;
    dataPagamentoFinal: string; 
    empreendimentoId:number;
    centroCustoId:number;
    contaGerencialId:number;
    formaPagamentoId:number;
  } = { 
    situacaoId:[1,4],
    contaCorrenteId:0,
    empresaId: 0,
    fornecedorId: 0,
    numeroDocumento: "",
    despesaId:0,
    pedidoId:0,
    dataVencimentoInicial: "",
    dataVencimentoFinal: "",
    dataPagamentoInicial:"",
    dataPagamentoFinal:"",
    empreendimentoId:0,
    centroCustoId: 0,
    contaGerencialId: 0,
    formaPagamentoId:0,
   }

  mounted() {
    new  FormaPagamentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => {this.formasPagamento = res.data.items}
    )

    new ContaCorrenteService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(res =>{
      this.contaCorrentes = res.data.items
    })

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(res =>{
      this.empreendimentos = res.data.items
    })

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then((res) => {
      this.pessoas = res.data.items;
    });

    new SituacaoDespesaParcelaService().ListarTudo().then((res) => {
      this.situacoes = res.data.items;
    });

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then((res) => {
      this.empresas = res.data.items
    });

    new CentroCustoService().Listagem().then((res) => {
      this.listaCentroCusto = res.data;
    });

    new ContaGerencialService().Listagem().then((res) => {
      this.listaContaGerencial = res.data;
    });
  }
}
